<template>
  <div class="page">
    <div class="page-sub-title">筛选查询</div>
    <div>
      <div class="search-item">
        <span class="label">操作日期</span>
        <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <span class="label">操作模块</span>
        <el-select v-model="form.operateModule" placeholder="请选择" size="small" style="width:120px">
          <el-option label="全部" value=""> </el-option>
          <el-option
              v-for="(item, index) in moduleList"
              :key="index"
              :label="item.v"
              :value="item.k"
          ></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <span class="label">操作人员</span>
        <el-input v-model="form.operatorName" placeholder="请输入操作人名称" size="small" style="width:200px"></el-input>
      </div>
      <div class="search-item">
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="page-sub-title">日志列表</div>
    <div>
      <el-table
          :data="tableData"
          style="width: 100%"
          border
          header-row-class-name="table-header"
      >
        <el-table-column align="center" prop="operateModuleName" label="操作模块"></el-table-column>
        <el-table-column align="center" prop="operateTypeName" label="操作类型"></el-table-column>
        <el-table-column align="center" prop="operateName" label="操作人员"></el-table-column>
        <el-table-column align="center" prop="serviceTypeName" label="业务ID"></el-table-column>
        <el-table-column align="center" prop="operateContent" label="操作内容">
          <template slot-scope="scope">
            <div v-html="scope.row.operateContent"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="operateTime" label="操作时间"></el-table-column>
      </el-table>
      <el-pagination
          background
          class="text-right m-t-16"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[5,10,15,20]"
          :current-page="form.pageNo"
          :page-size="form.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {get_common_operate_logs} from "@/api/log";
import {get_key_value} from "@/api/common";
import moment from "moment";

const form = {
  //酒店id   运维平台传0或者不传
  hotelId: "",
  // 客户端
  clientType:"DINGYI_MANAGER",
  //系统 具体选项见字典接口
  operateModule: "",
  operatorName: "",
  operateContent: "",
  startDate: "",
  endDate: "",
  pageNo: 1,
  size: 10
}
export default {
  name: "",
  data() {
    return {
      form: this.$deepClone(form),
      date: [],
      tableData: [],
      totalItems: 0,
      moduleList: [],
    };
  },
  watch: {
    date(val) {
      console.log('切换了时间',val)
      if (val.length) {
        this.form.startDate = moment(val[0]).format('YYYY-MM-DD')
        this.form.endDate = moment(val[1]).format('YYYY-MM-DD')
      }
    }
  },
  mounted() {
    this.getOption()
    this.getCommonOperateLogs()
  },
  methods: {
    getCommonOperateLogs() {
      get_common_operate_logs(this.form)
          .then(res => {
            console.log("请求数据接口get_common_operate_logs ==>", res);
            this.tableData = res.records
            this.totalItems = res.total
          })
    },
    getOption() {
      // 获取所属项目
      get_key_value({
        type: 'OPERATE_LOG_MODULE'
      }).then(res => {
        console.log('获取码表所属项目 ===>',res)
        this.moduleList = res.records
      }).catch(err => {
        console.log('获取码表所属项目 ===>',err)
      })
    },
    search() {
      this.getCommonOperateLogs()
    },
    reset() {
      this.form = this.$deepClone(form)
      this.date = []
      this.search()
    },
    handleSizeChange(size) {
      this.form.size = size
      this.search()
    },
    handleCurrentChange(pageNo) {
      this.form.pageNo = pageNo
      this.search()
    },
  },
};
</script>

<style>
.page{
  padding: 12px 24px 12px 24px;
  background-color: white;
}
</style>
