<template>
  <div class="page">
    <div class="page-sub-title">筛选查询</div>
    <div>
      <div class="search-item">
        <span class="label">访问时间</span>
        <el-select
            v-model="startDate"
            placeholder="请选择"
            size="small"
            style="width: 100px"
        >
          <el-option label="全部" :value="1"> </el-option>
          <el-option label="近三天" :value="2"> </el-option>
          <el-option label="近七天" :value="3"> </el-option>
          <el-option label="近一个月" :value="4"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <span class="label">登录类型</span>
        <el-select
            v-model="form.loginType"
            placeholder="请选择"
            size="small"
            style="width: 100px"
        >
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="登录" :value="1"> </el-option>
          <el-option label="退出" :value="2"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <span class="label">用户名</span>
        <el-input v-model="form.realName" placeholder="请输入用户名" size="small" style="width: 200px"></el-input>
      </div>
      <div class="search-item">
        <span class="label">登录IP</span>
        <el-input v-model="form.loginIp" placeholder="请输入登录IP" size="small" style="width: 200px"></el-input>
      </div>
      <div class="search-item">
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="page-sub-title">日志列表</div>
    <div>
      <el-table
          :data="tableData"
          style="width: 100%"
          border
          header-row-class-name="table-header"
      >
        <el-table-column align="center" prop="clientType" label="登录端"></el-table-column>
        <el-table-column align="center" prop="username" label="登录账号"></el-table-column>
        <el-table-column align="center" prop="realName" label="用户名"></el-table-column>
        <el-table-column align="center" prop="loginIp" label="登录IP"></el-table-column>
        <el-table-column align="center" prop="loginType" label="类型"></el-table-column>
        <el-table-column align="center" prop="loginContent" label="内容"></el-table-column>
        <el-table-column align="center" prop="loginAddress" label="登录地点"></el-table-column>
        <el-table-column align="center" prop="loginBrowser" label="登录浏览器"></el-table-column>
        <el-table-column align="center" prop="operateSystem" label="操作系统"></el-table-column>
        <el-table-column align="center" prop="loginTime" label="访问时间"></el-table-column>
      </el-table>
      <el-pagination
          background
          class="text-right m-t-16"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[5,10,15,20]"
          :current-page="form.pageNo"
          :page-size="form.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {get_key_value} from "@/api/common";
import moment from "moment";
import {get_login_logs} from "@/api/log";

const form = {
  hotelId: "",
  //客户端类型v2 见字典接口 DINGYI_MANAGER 和 YANHUIJIA
  clientType: "DINGYI_MANAGER",
  //登录类型 见字典接口
  loginType: 0,
  realName: "",
  loginIp: "",
  startDate: "",
  endDate: "",
  pageNo: 1,
  size: 10
}
export default {
  name: "login-logs",
  data() {
    return {
      value2: "1",
      form: this.$deepClone(form),
      startDate: 1,
      tableData: [],
      totalItems: 0,
    };
  },
  watch: {
    startDate(val) {
      switch (val) {
        case 1:
          // 全部
          this.form.startDate = ''
          break
        case 2:
          // 近三天
          this.form.startDate = moment().subtract(3, 'days').format('YYYY-MM-DD')
          break
        case 3:
          // 近七天
          this.form.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
          break
        case 4:
          // 近一个月
          this.form.startDate = moment().subtract(1, 'months').format('YYYY-MM-DD')
          break
        default:
          break
      }
    }
  },
  mounted() {
    this.getLoginLogs()
    this.getOption()
  },
  methods: {
    reset() {
      this.form = this.$deepClone(form)
      this.startDate = 1
      this.search()
    },
    search() {
      this.getLoginLogs()
    },
    getLoginLogs() {
      const form = this.$deepClone(this.form)
      if (form.loginType === 0) {
        delete form.loginType
      }
      get_login_logs(form)
          .then(res => {
            console.log('请求接口get_login_logs ===>',res)
            this.tableData = res.records
            this.totalItems = res.total
          }).catch(err => {
        console.log('请求接口get_login_logs ===>',err)
      })
    },
    getOption() {
      // 获取客户端类型
      get_key_value({
        type: 'CLIENT_TYPE'
      }).then(res => {
        console.log('获取码表客户端类型 ===>',res)
      }).catch(err => {
        console.log('获取码表客户端类型 ===>',err)
      })
    },
    handleSizeChange(size) {
      this.form.size = size
      this.search()
    },
    handleCurrentChange(pageNo) {
      this.form.pageNo = pageNo
      this.search()
    }
  }
};
</script>

<style>
</style>
