<template>
  <div>
    <div class="page-content h-100">
      <div class="info-box">
        <div class="flex list">
          <span class="c-1 mr-20 label">头像设置</span>
          <div class="text-center">
            <div class="i-img1" v-if="$store.state.userInfo.headImage">
              <img :src="$store.state.userInfo.headImage" alt="" />
              <el-popconfirm
                title="确定删除头像？"
                icon="el-icon-info"
                icon-color="red"
                @confirm="updateUserInfo('')"
              >
                <div slot="reference" class="delImg">
                  <i class="el-icon-delete font-size-22"></i>
                </div>
              </el-popconfirm>
            </div>
            <div v-else class="i-img2">
              <span>{{ fisrtName }}</span>
            </div>
            <div class="c-2 mt-10">
              推荐尺寸：40*40像素
            </div>
            <el-upload
              :headers="headers" 
              :action="baseURL + '/user/v1/upload'"
              ref="upload"
              accept=".jpg,.png,.jpeg,.JPG,.JPEG"
              :before-upload="beforeUpload"
              :on-success="handleSuccess">
              <el-button size="small" class="mt-10">
                <i class="el-icon-upload2"></i>
                更换头像
              </el-button>
            </el-upload>
          </div>
        </div>
        <div class="flex list">
          <span class="c-1 mr-20 label">姓名</span>
          <span class="c-3">{{$store.state.userInfo.realName}}</span>
        </div>
        <div class="flex list">
          <span class="c-1 mr-20 label">登录账号</span>
          <span class="c-3">{{$store.state.userInfo.username}}</span>
        </div>
        <div class="flex list">
          <span class="c-1 mr-20 label">密码</span>
          <span class="c-3">********</span>
          <span class="cursor-point c-4 ml-20" @click="visible = true">修改</span>
        </div>
        <div class="flex list">
          <span class="c-1 mr-20 label">手机号码</span>
          <span class="c-3">{{$store.state.userInfo.phone || '-'}}</span>
        </div>
        <div class="flex list">
          <span class="c-1 mr-20 label">职位</span>
          <span class="c-3">{{$store.state.userInfo.position || '-'}}</span>
        </div>
        <div class="text-center">
          <el-button class="mt-30" type="primary" size="small" @click="$router.go(-1)">返回上一页</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="visible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="hideDialog">
      <div class="dialog-body-content">
        <div class="top-title">
          1.密码需包含大写字母、小写字母、特殊符号、数字中的任意三项<br>
          2.密码长度为8-15位
        </div>
        <div class="p-20">
          <div class="flex flex-align-center item">
            <div class="title"><span class="c-red">*</span>旧密码</div>
            <el-input class="flex-1" v-model="form.oldPwd" type="password" placeholder="请填写旧密码" size="small"></el-input>
          </div>
          <div class="flex flex-align-center item">
            <div class="title"><span class="c-red">*</span>新密码</div>
            <el-input class="flex-1" v-model="form.pwd" type="password" maxlength="15" show-word-limit placeholder="请填写新密码" size="small"></el-input>
          </div>
          <div class="flex flex-align-center item">
            <div class="title"><span class="c-red">*</span>确认新密码</div>
            <el-input class="flex-1" v-model="confirmPwd" type="password" maxlength="15" show-word-limit placeholder="请在此输入新密码" size="small"></el-input>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="visible = false">取消</el-button>
        <el-button type="primary" size="small" @click="updatePwdByOldPwd">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { post_updateUserInfo, post_updatePwdByOldPwd } from "@/api/user";
import { get_user_info } from "@/api/login";
import { base_url } from "@/config";
// import { setStorage } from "@/utils/storage";
export default {
  name: "",
  components: {},
  data() {
    return {
      visible: false,
      baseURL: base_url,
      form: {
        oldPwd: '',
        pwd: ''
      },
      confirmPwd: ""
    };
  },
  watch: {
  },
  computed: {
    fisrtName() {
      const name = this.$store.state.userInfo.realName;
      if (name) {
        return name.substr(0, 1);
      } else {
        return "";
      }
    },
    headers() {
      return {
        "token": this.$store.state.token,
        "clientType": 'DINGYI_MANAGER'
      }
    }
  },
  methods: {
    beforeUpload(file) {
      // console.log(file,1)
      const isLt300K = file.size / 1024 < 5000;
      if (!isLt300K) {
        this.$message({
          message: "上传图片大小不能超过5M",
          type: "warning",
        });
        return;
      }
    },
    handleSuccess(file) {
      this.updateUserInfo(file.data)
    },
    //修改头像
    updateUserInfo(url) {
      post_updateUserInfo({
        headImgUrl: url
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        get_user_info().then(res =>{
          this.$store.commit('SET_USERINFO', res || {})
        })
      
      }) 
    },
    hideDialog() {
      this.visible = false
      this.confirmPwd = ''
      this.form = {
        oldPwd: '',
        pwd: '',
      }
    },
    //修改密码
    updatePwdByOldPwd() {
      var testPassword =/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,15}$/; 
      if(this.form.oldPwd === '') {
        this.$message({
          message: "请输入旧密码",
          type: "warning",
        });
      } else if (this.form.pwd === '') {
        this.$message({
          message: "请输入新密码",
          type: "warning",
        });
      } else if (this.confirmPwd === '') {
        this.$message({
          message: "请确认新密码",
          type: "warning",
        });
      } else if(testPassword.test(this.form.pwd)==false){
        this.$message({
          message: "新密码满足大写字母，小写字母，数字和特殊字符，其中任意三种组合,且长度为8-15",
          type: "warning",
        });
      } else if(this.form.pwd.length < 8) {
        this.$message({
          message: "新密码长度为8-15位",
          type: "warning",
        });
      } else if (this.form.pwd !== this.confirmPwd) {
        this.$message({
          message: "两次输入的新密码不一致，请确认",
          type: "warning",
        });
      } else {
         post_updatePwdByOldPwd({
          ...this.form
        }).then(() => {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.hideDialog()
          // setStorage('token', '');
          // location.reload()
        }) 
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h-100 {
  // height: calc(100vh - 188px);
  .info-box {
    width: 400px;
    height: 100%;
    margin: 0 auto;
    .list {
      margin-bottom: 40px;
      .label {
        width: 120px;
        text-align: right;
      }
      .i-img1 {
        width: 164px;
        height: 164px;
        border-radius: 164px;
        overflow: hidden;
        background: white;
        line-height: 164px;
        font-size: 60px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .delImg {
          display: none;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 50px;
          // display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0,0,0,0.6);
          text-align: center;
          color: white;
          cursor: pointer;
        }
        &:hover {
          .delImg {
            display: flex;
          }
        }
      }
      .i-img2 {
        width: 164px;
        height: 164px;
        border-radius: 164px;
        overflow: hidden;
        background: #dfe8f9;
        color: #487ad5;
        line-height: 164px;
        font-size: 60px;
      }
    }
  }
}
::v-deep .el-dialog {
  .el-dialog__body {
    padding: 0;
  }
}
.dialog-body-content {
  .top-title {
    // width: 100%;
    padding: 10px;
    text-align: center;
    background: #EFF5FA;
    color: #828F9A;
    font-size: 13px;
  }
  .item {
    line-height: 50px;
    .title {
      width: 90px;
      margin-right: 10px;
      text-align: right;
    }
  }
}
.c-1 {
  color: #a4a4a4;
}
.c-2 {
  color: #c2c9d6;
}
.c-3 {
  color: #333333;
}
.c-4 {
  color: #5390FF
}
.c-red {
  color: red;
}
::v-deep {
  .el-upload-list {
  display: none!important;
}
}
</style>
